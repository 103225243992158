.header-wrapper {
  position: relative;
  background-size: contain;
  background-position: center bottom;
  margin-bottom: 15px;
  height: 400px;
  background: url('../../images/hero-1600x600.jpg') no-repeat;

  .title-wrapper {
    position: absolute;
    bottom: 30px;
    left: 0px;
    right: 0px;
    background-color: rgb(255, 255, 255, 0.8);
  }

  h1 {
    color: #0c0817;
    font-size: 40px;
    padding: 10px 30px 0 30px;
  }

  .intro {
    padding: 0 0 10px 30px;
    font-size: 1.2em;
    font-weight: 300;
    color: #0c0817;
    font-style: italic;
  }
}

@media (max-width: 575px) {
  .header-wrapper {
    height: 216px;
    background: url('../../images/hero-576x216.jpg') no-repeat;

    h1 {
      font-size: 30px;
    }
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .header-wrapper {
    height: 216px;
    background: url('../../images/hero-992x372.jpg') no-repeat;

    h1 {
      font-size: 30px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .header-wrapper {
    height: 372px;
    background: url('../../images/hero-1200x450.jpg') no-repeat;

    h1 {
      font-size: 50px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .header-wrapper {
    height: 450px;
    background: url('../../images/hero-1600x600.jpg') no-repeat;

    h1 {
      font-size: 50px;
    }
  }
}

@media (min-width: 1600px) {
  .header-wrapper {
    height: 600px;
    background: url('../../images/hero-1600x600.jpg') no-repeat;

    h1 {
      font-size: 60px;
    }
  }
}

