@import '../../variables';

.share-wrapper {
  width: 200px;
  text-align: center;
  background-color: #f8f8f8;
  margin: 8px 15px 15px 0;
  float: left;

  button, a {
    margin: 15px;
    display: block;
    width: 85%;
  }

  a {
    text-decoration: none;
  }

  .btn .btn-usc {
    background-color: $usc_primary;
    border: 100px;
  }

  &.horizontal {
    width: auto;
    margin-left: auto;
    margin-right: auto;

    button {
      display: inline-block;
      width: auto;
    }
  }
}
